<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
    </el-row>
    <el-form label-width="100px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="货主">
            {{ basicInfo.cargoOwnerCode }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="计划库存">
            {{ basicInfo.planQuantity }}</el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">{{ $t('title.InventoryDetails') }}</span>
    </el-row>
    <el-form ref="addOutboundForm" :model="outboundForm" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <!-- <el-row :span="24">
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="逻辑仓" prop="outboundCode">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="货主" prop="outboundCode">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row> -->
      </div>
    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="logicWarehouseName" label="逻辑仓库" align="center" />
      <el-table-column prop="sku" label="SKU" align="center" />
      <el-table-column prop="style" label="Style" align="center" />
      <el-table-column prop="color" label="Color" align="center" />
      <el-table-column prop="size" label="Size" align="center" />
      <el-table-column prop="awaitOutQuantity" label="待出库库存" align="center">
        <template slot-scope="scope">
          <el-link type="primary" @click="_getPagedetailsOpern(scope.row)">
            {{ scope.row.awaitOutQuantity }}
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" @update="update" />
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
    <DetailsPopup
      :dialog-visible="dialogVisible"
      :details-popup-loading="detailsPopupLoading"
      :details-popup-list="detailsPopupList"
      :details-popup-obj="detailsPopupObj"
      :details-popup-pager="detailsPopupPager"
      :details-popup-column="detailsPopupColumn"
      :details-popup-base-infor="detailsPopupBaseInfor"
      @getPageList="_getPagedetailsPopup"
      @offDialogVisible="offDialogVisible"
    />
  </div>
</template>
<script>
import Paging from '@/components/Paging'
// 仓库计划库存明细
import { getCargoOwnerDetail } from '@/api/inventory-inquiry'
import DetailsPopup from '@/components/DetailsPopup'
import { getPageSkuWaitStock } from '@/api/stockin'

export default {
  components: {
    Paging,
    DetailsPopup
  },
  data() {
    return {
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      basicInfo: {},
      outboundForm: {
        sku: '',
        style: ''
      },
      tableLoading: false,
      tableData: [],
      detailsPopupList: [],
      detailsPopupObj: {},
      dialogVisible: false,
      detailsPopupLoading: false,
      detailsPopupPager: {
        size: 20,
        current: 1,
        total: 0
      },
      detailsPopupBaseInfor: [
        {
          title: '逻辑仓',
          key: 'logicWarehouseName'
        },
        {
          title: 'SKU',
          key: 'sku'
        }, {
          title: 'Style',
          key: 'style'
        }, {
          title: 'Color',
          key: 'color'
        }, {
          title: 'Size',
          key: 'size'
        }, {
          title: '待出库库存',
          key: 'awaitOutQuantity'
        }
      ],
      detailsPopupColumn: [{
        title: '待出库库存',
        key: 'pairs'
      }]
    }
  },
  computed: {
    queryParams() {
      const { cargoOwnerCode } = this.$route.query
      return Object.assign({}, this.pager, { cargoOwnerCode, planQuantity: this.basicInfo.planQuantity })
    }
  },
  mounted() {
    const { row } = this.$route.query
    this.basicInfo = row && JSON.parse(row) || {}
    this._getCargoOwnerDetail()
  },
  methods: {
    update(val) {
      this.pager = val
      this._getCargoOwnerDetail()
    },
    async _getCargoOwnerDetail(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await getCargoOwnerDetail(this.queryParams)
        this.pager = pager
        this.tableData = records
      } finally {
        this.tableLoading = false
      }
    },
    cancle() {
      this.$router.go(-1)
    },
    _getPagedetailsOpern(row) {
      const obj = { ...row, pairs: row.awaitOutQuantity }
      this.detailsPopupList = []
      this.detailsPopupObj = obj
      this.dialogVisible = true
      this.detailsPopupLoading = true
      this._getPagedetailsPopup(obj)
    },
    async _getPagedetailsPopup(row) {
      try {
        const { datas } = await getPageSkuWaitStock(row)
        this.detailsPopupLoading = false
        if (datas && Object.keys(datas).length) {
          const { records, pager } = datas
          this.detailsPopupList = records
          this.detailsPopupPager = pager
        }
      } finally {
        this.detailsPopupLoading = false
      }
    },
    offDialogVisible() {
      this.dialogVisible = false
    }

  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
